<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;基础配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
      <el-form-item label="前端域名">
        <el-input v-model="form.domain_name"></el-input>
      </el-form-item>
      <el-form-item label="后端域名">
        <el-input v-model="form.back_domain_name"></el-input>
      </el-form-item>
      <el-form-item label="客服电话">
        <el-input v-model="form.contact_mobile"></el-input>
      </el-form-item>
      <el-form-item label="客服QQ">
        <el-input v-model="form.contact_qq"></el-input>
      </el-form-item>
      <el-form-item label="企业版默认套餐">
        <el-select v-model="form.default_package" style="width:100%">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.packageName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="个人版默认资费">
        <el-input-number v-model.number="form.deduction_rate" :min="0"></el-input-number>（元/终端/年）
      </el-form-item>

      <el-form-item label="用户资源空间大小">
        <el-input-number v-model.number="form.resource_size" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="注册试用期限">
        <el-input-number v-model.number="form.trial_days" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="开启CDN支持">
        <el-radio-group v-model="form.cdn_switch" size="mini">
          <el-radio-button label="1">开启</el-radio-button>
          <el-radio-button label="0">关闭</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="CDN URL">
        <el-input v-model="form.cdn_url"></el-input>
      </el-form-item>
      <el-form-item label="允许注册房间号重复">
        <el-radio-group v-model="form.registered_room_number" size="mini">
          <el-radio-button label="1">允许</el-radio-button>
          <el-radio-button label="0">不允许</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmitEdit" style="width:100px">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        domain_name: "",
        back_domain_name: "",
        trial_days: "",
        contact_mobile: "",
        contact_qq: "",
        default_package: "",
        deduction_rate: "",
        resource_size: "",
        cdn_switch: "",
        cdn_url: "",
        registered_room_number: ""
      },
      options: []
    };
  },
  created() {
    this.initBasic();
  },
  methods: {
    initBasic() {
      getSystemConfigApi({ type: "basic" }).then(res => {
        if (res) {
          if (res.data.default_package_list != "[]") {
            const packGroupList = JSON.parse(res.data.default_package_list);
            for (let i = 0; i < packGroupList.length; i++) {
              packGroupList[i].id = packGroupList[i].id.toString();
            }
            this.options = packGroupList;
          }
          this.form = res.data;
        }
      });
    },

    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>